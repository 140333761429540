import { Button, Card, Form, Input, message } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loader from "../assets/Loader.gif";
import NotifyUser from "../utils/NotifyUser";
import { GATEWAY_URL, SIGNUP_SERVICE_GATEWAY_URL } from "../utils/constants.js";
import "./unAuthorized.scss";
import { LoadingOutlined } from "@ant-design/icons";

function CreateUser() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [submitButtonDisable, setSubmitButtonDisable] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isTokenVerified, setIsTokenVerified] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [tokenData, setTokenData] = useState({ isValid: false });

  const [error, setError] = useState(null);

  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const firstUser = new URLSearchParams(search).get("firstUser");

  const verifyToken = async (_token) => {
    await axios
      .get(`${GATEWAY_URL}/verify-token?token=${_token}`)
      .then((res) => {
        console.log(res.data);
        setTokenData({ isValid: true, ...res.data });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response?.status === 403) {
          // Handle 403 error
          setTokenData({
            isValid: false,
            message: error.response.data.message,
          });
        } else {
          setTokenData({ isValid: false, message: "This token Expired.." });

          // Handle other errors
        }
        NotifyUser(500, "Invalid Token");
      });
  };
  useEffect(() => {
    verifyToken(token);
  }, []);

  const onSignUpFormSubmit = async () => {
      setSubmitButtonDisable(true)
    try {
      if (firstUser) {
        setPopUp(true);
        await axios
          .post(
            `${GATEWAY_URL}/organisation/database`,
            {
              token,
              username,
              password: confirmPassword,
            },
            {
              dbname: "klglobaldatabase",
            }
          )
          .then(() => {
            message.success("sign up successfully", [1.5], () => {
              setPopUp(false);
              window.location.href = " https://app.kognics.com/login";
            });
          })
          .catch((err) => {
            setPopUp(false);

            NotifyUser(
              500,
              "Hmm.. that shouldn't happen. Please connect at dm@kognics.com"
            );
          });
      } else {
        await axios
          .post(`${GATEWAY_URL}/user/new/?token=${token}`, {
            username: username,
            password: password,
            DBname: tokenData.localdbname || null,
            registered_at: moment().format("YYYY-MM-DD HH:mm:ss"),
          })
          .then(
            () => {
              message.success(
                "sign up successfully",
                [1.5],
                () => (window.location.href = " https://app.kognics.com/login")
              );
            },
            (error) => {
              if (error.response && error.response.status === 409) {
                // Handle the conflict response here
                message.error("Username already exists");
              } else {
                // Handle other types of errors here
                message.error(
                  "Hmm.. that shouldn't happen. Please connect at dm@kognics.com"
                );
              }
            }
          );
      }
    } catch (error) {
      console.log(error);
      message.error("sign up failed!!", [1.5]);
    }
  };

  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingOutlined
          style={{
            fontSize: 24,
          }}
        />
      </div>
    );

  return (
    <div>
      {popUp ? (
        <img
          src={Loader}
          alt="loading..."
          style={{
            maxWidth: "20vw",
            margin: "auto",
            position: "absolute",
            zIndex: 1,
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
          }}
        />
      ) : null}
      {tokenData?.isValid ? (
        <Card className="center-card">
          <img src={require("./componyLogo.png")} alt="logo" />
          <div className="Inputs">
            <Form
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              onFinish={onSignUpFormSubmit}
            >
              <Form.Item
                label="Create User Name:"
                name="username"
                rules={[
                  {
                    type: "email",
                    message:
                      "Username should be in the form of an email. For Example (user@example.com)",
                  },
                  {
                    required: true,
                    // message: 'Please input your E-mail!',
                  },
                ]}
              >
                <Input
                  placeholder="User Name"
                  size="large"
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                  {
                    pattern:
                      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message:
                      "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character (Please do not use Hashtag in the Password).",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  placeholder="Enter password"
                  size="large"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        setSubmitButtonDisable(false);
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Confirm Password"
                  size="large"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="submit-btn"
                  htmlType="submit"
                  disabled={submitButtonDisable}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Card>
      ) : (
        <h1>{tokenData.message}</h1>
      )}
    </div>
  );
}

export default CreateUser;
