import { message } from "antd";

const NotifyUser = ({ apiStatus = 500, content = "", type = "destroy" }) => {
  if (type === "error") {
    if (apiStatus === 500) {
      message.error({
        content: "Internal server error. Please try again later.",
        className: "error-msg",
      });
    } else {
      message.error({
        content: content,
        className: "error-msg",
      });
    }
  } else if (type === "success") {
    message.success({
      content: content,
      className: "success-msg",
    });
  } else if (type === "loading") {
    message.loading("Please wait...", 0);
  } else if (type === "destroy") {
    message.destroy();
  }
};

export default NotifyUser;
