import { Button, Form, Input } from "antd";
import NotifyUser from "../utils/NotifyUser";
import { axiosInstance } from "../utils/axiosInstance";
import { GATEWAY_URL } from "../utils/constants";
import "./unAuthorized.scss";
import { useState } from "react";
import axios from "axios";

const Signup = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onFinish = async (values) => {
    setIsSubmitted(true);
    try {
      await axiosInstance.post(`${GATEWAY_URL}/user`, values).then(async () => {
        NotifyUser({
          apiStatus: 200,
          content: "Check your email for the signup link!",
          type: "success",
        });
      });
    } catch (error) {
      setIsSubmitted(false);
      console.log(error);
      NotifyUser({ apiStatus: 500, content: "Please retry" });
    }
  };

  return (
    <div className="login-signup-container">
      <div className="login-signup-form">
        <div className="form-container">
          <div className="company-info">
            <img src={require("./componyLogo.png")} alt="logo" />
            <p>Let there be learning.</p>
          </div>
          <div className="form-detail">
            <h2>Let's get you started.</h2>
          </div>
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Company Name"
              name="orgName"
              rules={[{ required: true, message: "Please input your Company Name!" }]}
            >
              <Input placeholder="Company name" size="large" />
            </Form.Item>
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[{ required: true, message: "Please input your First Name!" }]}
            >
              <Input placeholder="First name" size="large" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[{ required: true, message: "Please input your Last Name!" }]}
            >
              <Input placeholder="Last name" size="large" />
            </Form.Item>
            <Form.Item
              label="Email Id"
              name="email"
              rules={[{ required: true, message: "Please input your Email Id" }]}
            >
              <Input placeholder="Email id" size="large" />
            </Form.Item>
            <Form.Item>
              <Button
                disabled={isSubmitted}
                style={{
                  height: "40px",
                  width: "100%",
                  padding: "10px",
                  marginTop: "10px",
                }}
                className={!isSubmitted ? "submit-btn" : "ant-btn-disabled"}
                htmlType="submit"
              >
                {!isSubmitted ? "Sign Up" : "Check your email"}
              </Button>
            </Form.Item>
          </Form>
          <p className="login-page-link">
            Already have an account? SignIn&nbsp;
            {/* <Link to="login">SignIn here</Link>. */}
          </p>
        </div>
      </div>
      <div className="right-img">
        <img
          src={require("./sign.png")}
          style={{
            width: "50vw",
            height: "100vh",
            backgroundColor: "white",
          }}
          alt="Sign In Page"
        />
      </div>
    </div>
  );
};

export default Signup;
